import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { BloomBMember } from '../utils/types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BB_DOCTOR } from '../utils/constants'

const Members = () => {
  const members = useStaticQuery(graphql`
    query FamilyDocMembersQuery {
      allContentfulMember {
        nodes {
          id
          slug
          bloomBProfile
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  height: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)
  const {
    allContentfulMember: { nodes: bloombMembers },
  } = members
  return (
    <>
      {bloombMembers.length && (
        <section className="">
          <div className="box alt inner">
            <div className="grid-wrapper">
              {bloombMembers.map((member: BloomBMember) => {
                if (member.bloomBProfile?.toLowerCase() === BB_DOCTOR) {
                  return (
                    <div className="col-3" key={member.id}>
                      <div className="item__Container">
                        <Link to={`/profile/${member.slug}`}>
                          <span className="image">
                            <GatsbyImage
                              image={getImage(member.image.localFile)}
                              className="avatarImage"
                              alt={member.name}
                            />
                          </span>
                        </Link>
                        <Link to={`/profile/${member.slug}`}>
                          <h5 className="item__Container__title">
                            {member.name}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Members
