import React from 'react'
import BannerGeneric from '../components/BannerGeneric'
import Comments from '../components/Comments'
import { useSlack } from '../components/hooks'
import JustDoctors from '../components/JustDoctors'
import Layout from '../components/layout'
import { Seo } from '../components/Seo'
interface ForumProps {}
const Forum: React.FC<ForumProps> = () => {
  useSlack(`Ask a Doctor`)
  return (
    <>
      <Layout>
        <Seo title="Discussion Forum" />
        <BannerGeneric
          title="Ask a Doctor"
          description="Get quick medical advice for your health queries. Our medical panel consists of over 10+ doctors across the globe."
        />
        <section id="blogs" className="major">
          <div className="inner">
            <JustDoctors />
            <Comments title={`Ask a Doctor`} slug={`forum`} />
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Forum
